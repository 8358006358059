<style lang="scss">
.calenderad {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .plus-size {
    padding-top: 0 !important;
    height: 2px;
    padding-bottom: 0 !important;
    @media (max-width: 768px) {
      height: 8px;
    }
    strong {
      position: relative;
      top: -14px;
      left: 25px;
      font-size: 160%;
      display: inline-block;
      @media (max-width: 768px) {
        top: -11px;
      }
    }
  }
}
</style>

<template>
  <div class="content calenderad container">
    <h1>Calender Ad</h1>
    <p class="intro-txt">
      <strong>Ihr Event-Termin steht fest und keiner soll ihn vergessen?</strong><br />
      Über die Werbemöglichkeit Calender Ad wird Ihre Veranstaltung auf <a href="https://www.kleinezeitung.at" target="_blank">www.kleinezeitung.at</a> ausgespielt. Unsere User haben die Möglichkeit, sich den Termin direkt in Ihren Kalendern einzutragen.
    </p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-cal-ad.jpg" alt="Calender Ad" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Perfekt für Ihre Veranstaltung</h2>
          <p>Vermeiden Sie die Möglichkeit vergessen zu werden indem Sie über dieses Ad direkt die Verbindung zu den Kalendern unserer Nutzer herstellen.</p>
          <div class="preis-box">
            <span>Tarif ab:<strong>55,00 TKP</strong></span>
          </div>
          <CollapsibleButton link="calender-ad-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Calender Ad</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="calender-ad-collapse">
      <div class="row">
        <div class="col-lg-12">
          <h3>Produktdaten</h3>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="tabelle-box">
            <div class="headline">
              Calender Ad
            </div>
            <div class="row head-row ">
              <div class="col-lg-8 text-align-left hide-small">Produkt</div>
              <div class="col-lg-4 text-align-right hide-small">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-8 col-8 text-align-left ">Calender Ad <small>950 x 250 px</small></div>
              <div class="col-lg-4 col-4 text-align-right">ROS: 55,00</div>
              <div class="col-12 plus-size"><strong>+</strong></div>
              <div class="col-lg-8 col-8 text-align-left ">Mobile Calender Ad <small>300 x 600 px</small></div>
              <div class="col-lg-4 col-4 text-align-right">Select: 65,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/bsp-calender-ad.png" alt="Bsp. Calender Ad" class="img-fluid img-center" />
        </div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich als Tausenderkontaktpreis in Euro, exklusive 20 % Umsatzsteuer.</div>
    </Collapsible>
    <Modal></Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'CalenderAd',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
</script>
