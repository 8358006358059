
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'CalenderAd',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
